exports.shouldUpdateScroll = ({prevRouterProps, routerProps: {location: next}}) => {
  const prev = prevRouterProps && prevRouterProps.location;
  if (
    prev &&
    prev.pathname.indexOf("/station/") === 0 &&
    next.pathname.indexOf("/station/") === 0
  ) {
    return false;
  }
};
